<template>
  <div class="detail-container">
    <div class="device-detail-title">
      <i class="el-icon-arrow-left" @click="backToList"></i>
      <span>查看/处理记录</span>
    </div>
    <i class="el-icon-arrow-down" v-if="!showBase" @click="showMoreHandle"></i>
    <i class="el-icon-arrow-up" v-if="showBase" @click="showMoreHandle"></i>
    <template v-if="showBase">
      <div class="data-base-info">
        <div class="data-base-title">设备信息</div>
        <el-row class="data-row">
          <el-col :span="3">
            <div class="data-sn">设备编号</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content">{{ pageData.sn || "-" }}</div>
          </el-col>
          <el-col :span="3">
            <div class="data-sn">品牌</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content">{{ pageData.factory || "-" }}</div>
          </el-col>
          <el-col :span="3">
            <div class="data-sn">设备型号</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content">{{ pageData.model || "-" }}</div>
          </el-col>
          <el-col :span="3">
            <div class="data-sn">生产时间</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content">{{ pageData.productTime || "-" }}</div>
          </el-col>
        </el-row>
      </div>
      <div class="data-base-info" style="margin-top: 30px">
        <div class="data-base-title">业务信息</div>
        <el-row class="data-row">
          <el-col :span="3">
            <div class="data-sn">业务状态</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content">{{ pageData.status || "-" }}</div>
          </el-col>
          <el-col :span="3">
            <div class="data-sn">投营地区</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content">{{ pageData.location || "-" }}</div>
          </el-col>
          <el-col :span="3">
            <div class="data-sn">归属网点</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content">{{ pageData.storeName || "-" }}</div>
          </el-col>
          <el-col :span="3">
            <div class="data-sn">网点负责人</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content">{{ pageData.storeOwner || "-" }}</div>
          </el-col>
        </el-row>
        <el-row class="data-sub-row">
          <el-col :span="3">
            <div class="data-sn">当前用户</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content">{{ pageData.userName || "-" }}</div>
          </el-col>
          <el-col :span="3">
            <div class="data-sn">用户电话</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content">{{ pageData.userPhone || "-" }}</div>
          </el-col>
          <el-col :span="3">
            <div class="data-sn">当前订单</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content">{{ pageData.orderNo || "-" }}</div>
          </el-col>
          <el-col :span="3">
            <div class="data-sn">套餐价格</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content">{{ pageData.packagePrice || "-" }}</div>
          </el-col>
        </el-row>
        <el-row class="data-sub-row">
          <el-col :span="3">
            <div class="data-sn">租赁套餐</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content">{{ pageData.packageName || "-" }}</div>
          </el-col>
          <el-col :span="3">
            <div class="data-sn">实付金额</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content">{{ pageData.realPay || 0 }}</div>
          </el-col>
          <el-col :span="3">
            <div class="data-sn">租金金额</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content">{{ pageData.centAmount || 0 }}</div>
          </el-col>
          <el-col :span="3">
            <div class="data-sn">押金金额</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content">{{ pageData.depositAmount || 0 }}</div>
          </el-col>
        </el-row>
        <el-row class="data-sub-row">
          <el-col :span="3">
            <div class="data-sn">丢失保障金</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content">{{ pageData.security || 0 }}</div>
          </el-col>
          <el-col :span="3">
            <div class="data-sn">订单类型</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content">{{ pageData.orderType || "-" }}</div>
          </el-col>
        </el-row>
      </div>
      <div class="data-base-info" style="margin-top: 30px">
        <div class="data-base-title">云控信息</div>
        <el-row class="data-row">
          <el-col :span="3">
            <div class="data-sn">云控信息</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content">{{ pageData.cloudTypeText || "-" }}</div>
          </el-col>
          <el-col :span="3">
            <div class="data-sn">时间</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content">{{ pageData.cloudTime || "-" }}</div>
          </el-col>
          <el-col :span="3">
            <div class="data-sn">客服信息</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content">{{ pageData.customerTypeText || "-" }}</div>
          </el-col>
          <el-col :span="3">
            <div class="data-sn">时间</div>
          </el-col>
          <el-col :span="3">
            <div class="data-content">{{ pageData.customerTime || "-" }}</div>
          </el-col>
        </el-row>
      </div>
    </template>
    <div style="margin-top: 30px">
      <el-form
        ref="dataForm"
        :model="dataForm"
        :rules="rules"
        class="demo-form-inline"
        :disabled="type === 1 ? true : false"
      >
        <el-form-item label="设备编号" :label-width="'200px'" prop="sn">
          <!-- <el-input
            v-model="dataForm.sn"
            placeholder="设备编号"
            clearable
            @blur="inputHandle"
            :style="{ width: '500px' }"
          /> -->
          <el-select style="color: #000;" v-model="dataForm.sn" :style="{ width: '500px' }" filterable @change="inputHandle">
            <el-option
              v-for="item in deviceList"
              :key="item.id"
              :label="item.sn"
              :value="item.sn"
              style="color: #000;"
            ></el-option>
          </el-select>
          <el-button style="margin-left: 30px;" type="primary" @click="copyHandle()">复制</el-button>
        </el-form-item>

        <el-form-item label="发生时间" :label-width="'200px'" prop="occurrenceTime">
          <el-date-picker
            v-model="dataForm.occurrenceTime"
            type="datetime"
            @change="timeChange"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="来源" :label-width="'200px'" prop="source">
          <el-select
            v-model="dataForm.source"
            placeholder="信息来源"
            size="small"
            class="area-form-item"
            :style="{ width: '500px' }"
          >
            <el-option
              v-for="(item, index) in readyData.sourceList"
              :key="index"
              :label="item.typeName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="联系人类型" :label-width="'200px'" prop="contact">
          <el-select
            v-model="dataForm.contact"
            placeholder="联系人类型"
            size="small"
            class="area-form-item"
            :style="{ width: '500px' }"
          >
            <el-option
              v-for="(item, index) in readyData.contactTypeList"
              :key="index"
              :label="item.typeName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="联系人姓名"
          :label-width="'200px'"
          prop="contactName"
        >
          <el-input
            v-model="dataForm.contactName"
            placeholder="联系人姓名"
            clearable
            :style="{ width: '500px' }"
          />
        </el-form-item>

        <el-form-item
          label="联系人方式"
          :label-width="'200px'"
          prop="contactPhone"
        >
          <el-input
            v-model="dataForm.contactPhone"
            placeholder="联系人方式"
            clearable
            :style="{ width: '500px' }"
          />
        </el-form-item>

        <el-form-item
          label="业务类型"
          :label-width="'200px'"
          prop="tagType"
        >
          <el-select
            v-model="dataForm.tagType"
            placeholder="业务类型"
            size="small"
            class="area-form-item"
            :style="{ width: '500px' }"
            @change="changeTagHandle"
          >
            <el-option
              v-for="(item, index) in readyData.tagList"
              :key="index"
              :label="item.typeName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="业务子类"
          :label-width="'200px'"
          prop="childTagArr"
        >
          <el-select
            v-model="dataForm.childTagArr"
            placeholder="问题子类"
            size="small"
            class="area-form-item"
            :style="{ width: '500px' }"
            multiple
            @change="$forceUpdate()"
          >
            <el-option
              v-for="(item, index) in subTagList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="客服记录"
          :label-width="'200px'"
          prop="customerServiceRecordJson"
        >
          <el-input
            v-model="dataForm.customerServiceRecordJson"
            placeholder="客服记录"
            clearable
            type="textarea"
            rows="5"
            :style="{ width: '500px' }"
          />
        </el-form-item>
        <div v-for="(item, index) in dataForm.remarkJson" :key="index">
          <el-form-item
            :label="'处理记录说明-' + (item.personText || '')"
            :label-width="'200px'"
            prop="remarkJson"
          >
            <el-input
              v-model="item.info"
              placeholder="处理记录说明"
              clearable
              type="textarea"
              rows="5"
              :style="{ width: '500px' }"
            />
          </el-form-item>
        </div>
        <el-form-item
          label="问题类型"
          :label-width="'200px'"
          prop="questionType"
        >
          <el-select
            v-model="dataForm.questionType"
            placeholder="问题类型"
            size="small"
            class="area-form-item"
            :style="{ width: '500px' }"
            @change="changeHandle"
          >
            <el-option
              v-for="(item, index) in readyData.questionTypeList"
              :key="index"
              :label="item.typeName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="问题子类"
          :label-width="'200px'"
          prop="childProblemArr"
        >
          <el-select
            v-model="dataForm.childProblemArr"
            placeholder="问题子类"
            size="small"
            class="area-form-item"
            :style="{ width: '500px' }"
            multiple
            @change="$forceUpdate()"
          >
            <el-option
              v-for="(item, index) in subList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="处理结果" :label-width="'200px'" prop="result">
          <el-select
            v-model="dataForm.result"
            placeholder="处理结果"
            size="small"
            class="area-form-item"
            :style="{ width: '500px' }"
          >
            <el-option
              v-for="(item, index) in readyData.resultList"
              :key="index"
              :label="item.typeName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="金额记录"
          :label-width="'200px'"
          prop="purposeOfFunds"
        >
          <el-select
            v-model="dataForm.purposeOfFunds"
            placeholder="金额记录"
            size="small"
            class="area-form-item"
            :style="{ width: '500px' }"
          >
            <el-option
              v-for="(item, index) in readyData.purposeEnumList"
              :key="index"
              :label="item.value"
              :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="支付方式" :label-width="'200px'" prop="payWay	">
          <el-select
            v-model="dataForm.payWay"
            placeholder="支付方式"
            size="small"
            class="area-form-item"
            :style="{ width: '500px' }"
          >
            <el-option
              v-for="(item, index) in readyData.payWayEnumList"
              :key="index"
              :label="item.value"
              :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="支付金额" :label-width="'200px'" prop="amount">
          <el-input
            v-model="dataForm.amount"
            placeholder="支付金额"
            clearable
            :style="{ width: '500px' }"
            onkeyup="this.value=(this.value.match(/\d+(\.\d{0,2})?/)||[''])[0]"
          />
        </el-form-item>
        <el-form-item label="上传附件" :label-width="'200px'" prop="orgLogo">
          <el-upload
            class="logo-uploader"
            action="/api/file/upload"
            multiple
            :limit="10"
            :headers="{ token: getToken() }"
            :show-file-list="false"
            accept="image/png, image/jpeg"
            :on-success="onSuccessHandel"
          >
            <div slot="trigger" class="logo-uploader-container">
              <img
                class="upload-icon"
                :src="require('@/assets/img/upload-icon.png')"
              />
            </div>
            <div class="logo-view">
              <img
                class="default-logo"
                v-for="(item, index) in dataForm.attachmentJson"
                :key="index"
                :src="item"
                @click="bigImgHandle(item)"
              />
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="流转其他处理人"
          :label-width="'200px'"
          prop="currentPerson"
        >
          <el-select
            v-model="dataForm.currentPerson"
            placeholder="流转其他处理人"
            size="small"
            class="area-form-item"
            clearable
            :style="{ width: '500px' }"
          >
            <el-option
              v-for="(item, index) in persons"
              :key="index"
              :label="item.userName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="buttons" v-if="type !== 1">
        <div class="button-position">
          <el-button type="primary" :loading="loading" @click="dataFormSubmitHandle(0)"
            >保存</el-button
          >
          <el-button
            type="primary" :loading="loading"
            :disabled="dataForm.currentPerson ? true : false"
            @click="dataFormSubmitHandle(1)"
            >完结</el-button
          >
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="visible"
      title="图片预览"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="40%"
    >

      <img :src="imgSrc" style="height: 500px; width: 500px;padding-left: 100px;"/>
    </el-dialog>
  </div>
</template>

<script>
import "./AddCustomer.less";
import axios from "@/utils/axios";
import moment from "moment";
export default {
  name: "InfoCustomer",
  data() {
    return {
      visible: false,
      type: null,
      loading: false,
      dataForm: {
        sn: null,
        source: null,
        organId: null,
        amount: null,
        attachmentJson: [],
        contact: null,
        contactName: null,
        contactPhone: null,
        id: null,
        payWay: null,
        purposeOfFunds: null,
        questionType: null,
        result: null,
        customerServiceRecordJson: null,
        remarkJson: [],
        childProblem: null,
        currentPerson: null,
        childProblemArr: null,
        status: null,
        userId: null,
        deviceCurrentInfo: null,
        tagType: null,
        childTag: null,
        childTagArr: null,
        occurrenceTime: null
      },
      pageData: {},
      readyData: {},
      persons: [],
      subList: [],
      historyList: [],
      imgSrc: null,
      deviceList: [],
      subTagList: [],
      showBase: false,
    };
  },
  computed: {
    rules() {
      return {
        sn: [{ required: true, message: "必填项不能为空", trigger: "blur" }],
        source: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ],
        contact: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ],
        occurrenceTime: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ],
        tagType: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ],
        customerServiceRecordJson: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ],
        result: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ],
        // contactPhone: [
        //   { validator: this.validatePhoneNumber, trigger: "blur" },
        // ],
      };
    },
  },
  created() {
    this.dataForm.organId = localStorage.getItem("organId");
    this.dataForm.userId = localStorage.getItem("userId");
  },
  mounted() {
    this.dataForm.id = this.$route.query.id;
    this.type = Number(this.$route.query.type);
    this.dataForm.remarkJson.push({ info: null, person: null, personText: '', updateTime: ''});
    this.getSourceList();
    this.getPersonList();
    this.getDeviceList()
    if (this.dataForm.id) {
      this.getDataInfo();
      this.getHistoryInfo();
    }
  },
  methods: {
    copyHandle() {
      let url = this.dataForm.sn;
      let oInput = document.createElement('input');
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message({
        message: '复制成功',
        type: 'success'
      });
    },
    showMoreHandle() {
      this.showBase = !this.showBase
    },
    getToken() {
      return localStorage.getItem("user-token");
    },
    bigImgHandle(src) {
      this.visible = true
      this.imgSrc = src
    },
    //时间选择
    timeChange(data) {
      if (data) {
        this.dataForm.occurrenceTime = this.DateToStr(data) 
      }
    },
    //日期转字符串
    DateToStr(date) {
      var year = date.getFullYear(); //年
      var month = date.getMonth(); //月
      var day = date.getDate(); //日
      var hours = date.getHours(); //时
      var min = date.getMinutes(); //分
      var second = date.getSeconds(); //秒
      return (
        year +
        "-" +
        (month + 1 > 9 ? month + 1 : "0" + (month + 1)) +
        "-" +
        (day > 9 ? day : "0" + day) +
        " " +
        (hours > 9 ? hours : "0" + hours) +
        ":" +
        (min > 9 ? min : "0" + min) +
        ":" +
        (second > 9 ? second : "0" + second)
      );
    },
    //获取当前机构下所有设备
    getDeviceList() {
       axios
        .post("/api/device/getDevices", { id: this.dataForm.organId })
        .then((rs) => {
          this.deviceList = rs;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    //获取故障处理人列表
    getPersonList() {
      axios
        .post("/api/cloudControl/persons", { id: this.dataForm.organId })
        .then((rs) => {
          this.persons = rs;
        });
    },
    //返回上一页
    backToList() {
      this.$store.dispatch("tagsView/delView", this.$route);
      this.$router.go(-1);
    },
    validatePhoneNumber(rule, value, callback) {
      if (/^1[3|4|5|7|8][0-9]{9}$/.test(value)) {
        callback();
      } else {
        callback(new Error("请输入合法的手机号"));
      }
    },
    getDataInfo() {
      axios
        .post(`/api/customerServiceCenter/detail`, { id: this.dataForm.id })
        .then((res) => {
          let dataForm = { ...res };
          if(this.type === 2) {
            dataForm.currentPerson = null
          }
          this.dataForm = dataForm
          this.inputHandle();
          this.changeHandle(res.questionType);
          this.changeTagHandle(res.tagType)
          if(res.childProblem) {
            let arr = res.childProblem.split(",");
            let childProblemArr = []
            arr.forEach(element => {
              childProblemArr.push(Number(element))
            })
            this.dataForm.childProblemArr = childProblemArr
          }
          if(res.childTag) {
            let arr = res.childTag.split(",");
            let childTagArr = []
            arr.forEach(element => {
              childTagArr.push(Number(element))
            })
            this.dataForm.childTagArr = childTagArr
          }
          if(Number(this.type) === 2) {
            this.dataForm.remarkJson.push({ info: null, person: null, personText: '', updateTime: '' });
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    getHistoryInfo() {
      axios
        .post(`/api/customerServiceCenter/history`, { id: this.dataForm.id })
        .then((res) => {
          this.historyList = res;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    //获取来源列表
    getSourceList() {
      axios
        .post(`/api/customerServiceCenter/ready`, {id: this.dataForm.organId})
        .then((res) => {
          this.readyData = res;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    onSuccessHandel(response) {
      this.dataForm.attachmentJson.push(response.data.path);
    },
    inputHandle() {
      axios
        .post(`/api/customerServiceCenter/other`, { id: this.dataForm.sn })
        .then((res) => {
          this.pageData = res;
          this.dataForm.deviceCurrentInfo = JSON.stringify(res)
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    changeHandle(value) {
      axios
        .post(`/api/customerServiceCenter/getChild`, { id: value })
        .then((res) => {
          this.subList = res;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    changeTagHandle(value) {
      axios
        .post(`/api/customerServiceCenter/getChild`, { id: value })
        .then((res) => {
          this.subTagList = res;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    subChange(value) {
      this.dataForm.childProblem = value.join(",");
    },
    dataFormSubmitHandle(type) {
      this.$refs["dataForm"].validate((valid) => {
        if (!valid) {
          return false;
        }
        let userId = localStorage.getItem("userId");
        let { dataForm } = this;
        dataForm.status = type;
        dataForm.userId = userId
        if(dataForm.childProblemArr && dataForm.childProblemArr.length > 0) {
          dataForm.childProblem = ""
          dataForm.childProblem = dataForm.childProblemArr.join(",");
        } else {
          dataForm.childProblem = ""
        }
        if(dataForm.childTagArr && dataForm.childTagArr.length > 0) {
          dataForm.childTag = ""
          dataForm.childTag = dataForm.childTagArr.join(",");
        } else {
          dataForm.childTag = ""
        }
        let remarkJson = dataForm.remarkJson || [];
        for(var i = 0; i < remarkJson.length; i++) {
          if (!remarkJson[i].info) {
            remarkJson.splice(i, 1)
          }
        }
        dataForm.remarkJson = remarkJson
        let that = this;
        this.loading = true
        axios
          .post(`/api/customerServiceCenter/save`, dataForm)
          .then((res) => {
            if (res) {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 500,
              });
              this.loading = false
              window.opener = null;
              window.open("about:blank", "_top").close()
            } else {
            }
          })
          .catch((err) => {
            this.loading = false
            return this.$message.error(err);
          });
      });
    },
  },
};
</script>
<style>
  .el-input.is-disabled /deep/ .el-input__inner {
    color: #000;
  }

  .el-textarea.is-disabled /deep/ .el-textarea__inner {
    color: #000;
  }

  .el-select.el-select__tags.el-tag el-tag--info el-tag--mini el-tag--light {
    color: #000;
  }
</style>